.user-box {
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    width: auto;
    height: 20px;
    box-shadow: -2px 2px 4px #464649;
    transition: transform 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    background-color: linear-gradient(to right, #b2cadd 0%, #b2cadd 100%);
}

.user-box:hover {
    transform: scale(1.015);
}
.user-box:active {
    transform: scale(0.99);
}
.user-name {
    font-size: 1.35em;
    font-weight: 600;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-column: 1;
}

.user-detail {
    font-size: 1.1em;
    font-weight: 550;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
}
