.setting-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.input-box {
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 10px;
    font-size: 1em;
    background-color: #f9ede1;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-form {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.input-btn {
    font-weight: bold;
    font-family: "Nanum Myeongjo", serif;
    color: black;
    cursor: pointer;
    padding: 10px 15px;
    margin-top: 6px;
}

.input-btn:hover {
    color: #f9ede1;
    background-color: #65aed5;
}

.saved-message {
    margin-top: 6px;
    color: #5f6264;
    align-self: center;
    animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
