body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.main-container {
    width: 600px;
    background-color: linear-gradient(to right, #b2cadd 0%, #b2cadd 100%);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
}

.tabs {
    display: flex;
    justify-content: center;
}

.tab {
    padding: 16px;
    cursor: pointer;
    font-family: "Nanum Myeongjo", serif;
    flex: 1;
    background-color: transparent;
    color: rgb(0, 0, 0);
    font-weight: bold;
    transition: 0.2s;
}

.tab:hover,
.tab.active {
    color: #f9ede1;
    background-color: #65aed5;
}

.tab-content {
    padding: 40px;
}

.join-header {
    margin-top: -12px;
    margin-bottom: 30px;
}

.section-container {
    width: 100%;
}

.input {
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    border: none;
    border-bottom: 2px solid 1px solid #000000;
    font-size: 16px;
    box-sizing: border-box;
}

button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: transparent;
    color: black;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: bold;
    font-family: "Nanum Myeongjo", serif;
}

.error-message {
    margin-top: 10px;
}

#root {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input {
    /* font-family: "Nanum Myeongjo", serif; */
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    border-radius: 8px;
    background-color: #f9ede1;
}
