.upload-boxes-container {
    font-size: 1.2em;
    width: 70vw;
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.upload-instruction-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    padding-inline: 20px;
    border-radius: 5px;
    box-shadow: -7px 8px 8px #464649;
    border: 1px solid black;
    border-radius: 8px;
    width: 30vw;
}
.upload-instruction {
    width: auto;
    text-align: center;
    font-size: 1em;
    font-weight: 550;
    line-height: 1.3;
    color: rgb(0, 0, 0);
}
.drop-box-container {
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 25vw;
    text-align: center;
    box-shadow: -7px 8px 8px #464649;
    border: 1px solid black;
    border-radius: 8px;
    transition: 0.2s;
    width: 30vw;
}
.drop-box {
    width: 80%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2em;
    font-weight: 1000;
    border: 2px solid black;
    border-radius: 8px;
    transition: 0.2s;
}
.drop-box:hover {
    color: #f9ede1;
    background-color: #65aed5;
}
.large-icon {
    font-size: 36px;
}
.submission-details {
    margin-top: 10px;
}
.upload-buttons-container {
    display: flex;
    flex-direction: row;
    width: 80%;
}
.upload-button {
    font-family: "Nanum Myeongjo", serif;
    color: black;
    border-width: 2px;
    transition: 0.2s;
    width: 50%;
}
.upload-button:hover {
    color: #f9ede1;
    background-color: #65aed5;
}
.popup-text {
    margin-top: 10px;
}
