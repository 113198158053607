.navbar {
    /*background: linear-gradient(90deg, #212a31, #2e3944); */
    height: 65px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    font-family: "PT Sans", sans-serif;
}

.navbar-logo {
    color: #212a31;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    position: absolute;
    margin-top: 10px;
    top: 0;
    left: 0;
    transition: color 0.2s ease-in-out;
}

.navbar-logo:hover {
    color: #65aed5;
}

.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: -22rem;
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-links {
    color: #212a31;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: color 0.2s ease-in-out;
}

.nav-links:hover {
    color: #65aed5;
}

.fa-bars {
    color: #212a31;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 95vh;
        position: absolute;
        top: 40px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        margin-top: -250px;
        margin-left: -75px;
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: white;
    }

    .nav-links:hover {
        background-color: #65aed5;
        border-width: 0;
        width: 100%;
        color: white;
    }

    .menu-icon {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #212a31;
        font-size: 1.8rem;
        display: flex;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 100%;
        background: #1888ff;
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #65aed5;
        transition: 250ms;
        color: white;
    }
}
