.ranking-settings-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 140px;
    margin-right: 8px;
    gap: 8px;
    align-self: flex-start;
}

.set-manual-update {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    padding: 10px 10px;
    height: 80px;
    border: 1px solid #000000;
    border-radius: 4px;
    background-color: transparent;
}

.manual-update-input {
    width: 110px;
    background-color: #f9ede1;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    font-family: "Nanum Myeongjo", serif;
}

.manual-update-save {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    width: 115px;
    padding: 2px;
    text-align: center;
}

.export-csv-button {
    width: 120px;
    padding: 10px 10px;
    border: 1px solid #000000;
    border-radius: 4px;
    text-align: center;
    background-color: transparent;
    color: black;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s;
    font-family: "Nanum Myeongjo", serif;
    text-decoration: none;
}

.export-csv-button:hover {
    color: #f9ede1;
    background-color: #65aed5;
}

.manual-update-save:hover {
    color: #f9ede1;
    background-color: #65aed5;
}

.ranking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.header {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 16px;
}

.total-comparisons {
    margin-bottom: 10px;
}

.filter-buttons {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    margin-top: 120px;
    gap: 8px;
    margin-left: 16px;
}

.filter-button {
    padding: 10px 15px;
    border: 1px solid #000000;
    background-color: transparent;
    color: black;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s;
    font-family: "Nanum Myeongjo", serif;
}

.ranking-tabs {
    margin-top: -20px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
    width: 800px;
}

.tab {
    padding: 16px;
    cursor: pointer;
    font-family: "Nanum Myeongjo", serif;
    flex: 1;
    background-color: transparent;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 1.2rem;
    transition: 0.2s;
    flex-grow: 1;
    text-align: center;
}

.tab:hover,
.tab.active {
    color: #f9ede1;
    background-color: #65aed5;
}

.filter-button:hover {
    color: #f9ede1;
    background-color: #65aed5;
}

.filter-button.active {
    background-color: #65aed5;
}

.ranking-screen {
    width: 800px;
    height: calc(100vh - 150px - 120px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
    border-radius: 8px;
    background-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.active-rankings {
    display: flex;
    align-items: center;
    margin: 0px;
}

.manual-button {
    border: 1px solid #000000;
    border-radius: 8px;
    height: 60px;
    width: 70px;
    box-shadow: -4px 4px 8px #4f4f52;
    transition: transform 0.2s;
    font-size: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: linear-gradient(to right, #b2cadd 0%, #b2cadd 100%);
}

.manual-button:hover {
    transform: scale(1.015);
}

.manual-button:active {
    transform: scale(0.99);
}

.pages {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.page-button {
    margin: 0 2.5px;
    padding: 10px 15px;
    border: none;
    background-color: transparent;
    color: black;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s;
    font-family: "Nanum Myeongjo", serif;
}

.page-button:hover {
    color: #f9ede1;
    background-color: #65aed5;
}

.page-button.active {
    color: #f9ede1;
    background-color: #65aed5;
}
