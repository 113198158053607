.admin-wrapper {
    width: 700px;
    background-color: linear-gradient(to right, #b2cadd 0%, #b2cadd 100%);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
}

.settings-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin-container {
    padding: 15px;
    padding-bottom: 20px;
}

.settings-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.admin-header {
    display: flex;
    justify-content: center;
}

.reject-container {
    display: flex;
    flex-direction: column;
    width: 33%;
}

.push-container {
    display: flex;
    flex-direction: column;
    width: 33%;
}

.timer-setting-container-top {
    display: flex;
    flex-direction: column;
    width: 33%;
}

.timer-setting-container-center {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 33%;
}
