.applicant-box {
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 16px;
    margin: 8px;
    margin-right: 12px;
    width: 92%;
    height: 40px;
    box-shadow: -4px 4px 8px #464649;
    transition: transform 0.2s;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 16px;
    background-color: linear-gradient(to right, #b2cadd 0%, #b2cadd 100%);
}

.applicant-box:hover {
    transform: scale(1.015);
}
.applicant-box:active {
    transform: scale(0.99);
}

.applicant-name {
    font-size: 1.35em;
    font-weight: 600;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-column: 1;
}

.applicant-details {
    display: contents;
}
.applicant-detail {
    font-size: 1.1em;
    font-weight: 550;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
}

.applicant-detail:nth-child(1) {
    grid-column: 2;
}

.applicant-detail:nth-child(2) {
    grid-column: 3;
}

.applicant-detail:nth-child(3) {
    grid-column: 4;
}

.excluded .applicant-detail {
    grid-column: auto;
}

.applicant-details-excluded {
    font-size: 1.1em;
    font-weight: 550;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
    text-align: right;
    grid-column: 4;
}
