body {
    margin: 0;
    height: 100%;
    font-family: "Nanum Myeongjo", serif;
    /* font-family: "Lora", serif; */
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    background: #1c92d2; /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #f2fcfe #1c92d2); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #f2fcfe, #1c92d2);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
