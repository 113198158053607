.compare-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    height: calc(100vh - 55px); /* Full viewport height */
    z-index: 1000;
}

.timer-container {
    display: flex;
    justify-content: center;
    width: 3.5%;
}
.timer {
    font-size: 1.5em;
    font-weight: bold;
    display: inline-flex;
    margin-top: 30px;
    justify-content: center;
    margin-bottom: 20px;
    padding: 5px 10px;
    border-radius: 10px;
    color: #f9ede1;
    background-color: #65aed5;
}
.resumes {
    display: flex;
    flex-direction: row;
}
.resume-render-container {
    display: flex;
    flex: row-reverse;
}

.auto-btns-container {
    display: flex;
    flex-direction: column;
}

.auto-btn {
    width: 100px;
    padding: 10px 15px;
    border: 1px solid #000000;
    background-color: transparent;
    color: black;
    cursor: pointer;
    font-weight: bold;
    transition: 0.2s;
    font-family: "Nanum Myeongjo", serif;
}

.auto-btn:hover {
    color: #f9ede1;
    background-color: #65aed5;
}
