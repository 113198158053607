.create-session-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    background-color: #f9ede1;
}

.input-field:first-child {
    margin-top: 0;
}

.create-header {
    margin-top: -30px;
}

.submit-button {
    margin-top: 30px;
    font-weight: bold;
    background-color: linear-gradient(to right, #b2cadd 0%, #b2cadd 100%);
    font-family: "Nanum Myeongjo", serif;
    color: black;
    transition: 0.2s;
}

.submit-button:hover {
    color: #f9ede1;
    background-color: #65aed5;
}
.popup-text {
    margin-top: 10px;
}
